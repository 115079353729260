<template>
  <div :style="{ width: src.with_media || (src.media && src.media.url) ? '100%' : 'auto' }">
    <v-btn
      :disabled="disabled"
      v-if="!isFormat"
      icon
      class="mt-1"
      @click="$emit('open')">
      <v-icon> {{ icon }} </v-icon>
    </v-btn>
    <template v-else>
      <slot name="preview"></slot>
    </template>

    
    <!-- @click:outside="$emit('close')" -->
    <v-dialog
      v-model="show_dialog"
      persistent
      width="1000"
      scrollable>
      <v-card>
        <v-card-title>
          <span>Sisipkan {{ type }}</span>
          <v-spacer></v-spacer>
          <v-icon color="red" size="30" @click="$emit('close')">mdi-close</v-icon>
        </v-card-title>
        <v-col class="px-6 py-0">
          <slot name="tabs"></slot>
        </v-col>

        <v-divider></v-divider>
        <v-card-text class="d-md-flex pa-0" style="height: 510px;">
          <v-col class="fill-height background pa-0 overflow-auto">
            <slot name="tab-items"></slot>
          </v-col>

          <!-- FORNEXT: make expand transition -->
          <div
            class="col-md-4 col-12 pa-0"
            v-if="selected && (selected.url || selected.public_url)">
            <v-col class="white pa-0 fill-height-md overflow-auto">
              <div class="d-md-flex">
                <v-divider class="hidden-md-and-up"></v-divider>
                <v-divider class="hidden-sm-only" vertical></v-divider>
                <div class="pa-6">
                  <div class="title mb-2">Information media</div>
                  
                      <v-text-field
                        class="py-3"
                        :readonly="readonly"
                        outlined
                        dense
                        color="#F05326"
                        label="Title"
                        hide-details="auto"
                        autocomplete="off"
                        v-model="selected.title">
                      </v-text-field>

                      <v-text-field
                        class="py-3"
                        :readonly="readonly"
                        dense
                        outlined
                        color="#F05326"
                        label="Caption"
                        hide-details="auto"
                        autocomplete="off"
                        v-model="selected.caption">
                      </v-text-field>

                      <v-text-field
                        class="py-3"
                        :readonly="readonly"
                        dense
                        outlined
                        color="#F05326"
                        label="Alt"
                        hide-details="auto"
                        autocomplete="off"
                        v-model="selected.alt">
                      </v-text-field>

                      <v-textarea
                        class="py-3"
                        :readonly="readonly"
                        outlined
                        dense
                        hide-details="auto"
                        label="Description"
                        rows="3"
                        no-resize
                        color="#F05326"
                        v-model="selected.description">
                      </v-textarea>

                  <v-row>
                    <v-col v-if="!isFormat" cols="12">
                      <div class="red--text small">
                        Please select {{ type }} file format
                      </div>
                    </v-col>
                    <v-col v-if="error" cols="12">
                      <div class="red--text small">
                        {{ error }}
                      </div>
                    </v-col>
                    <v-col>
                      <v-btn
                        color="#F05326"
                        elevation="0"
                        class="white--text"
                        @click="$emit('set', selected)"
                        :loading="$store.state.process.run"
                        :disabled="isDisabled">
                        Lanjut
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-col>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "attachment-layout",
  props: {
    type: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    src: { type: Object, required: true },
    tab: { type: Number, required: true },
    selected: { type: Object, default: () => ({}) },
    readonly: { type: Boolean, default: false },
    show: { type: Boolean, default: false },
    saving: { type: Boolean, default: false },
    error: { type: String, default: "" },
  },
  data: () => {
    return {
      show_dialog: false,
    };
  },
  created() {
    this.show_dialog = this.show;
  },
  computed: {
    isDisabled() {
      return this.disabled || !this.isFormat;
    },
    icon() {
      switch (this.type) {
        case "audio":
          return "mdi-music";
        case "image":
          return "mdi-image-plus";
        default:
          return "mdi-information";
      }
    },
    isFormat() {
      return (
        (this.selected.file && this.selected.file.type.includes(this.type))
        || (this.selected.type && this.selected.type == this.type)
        || (this.src.media && this.src.media.type == this.type)
      );
    },
  },
  methods: {
    async set() {
      await this.$emit("set", this.selected);
    },
  },
  watch: {
    show(val) {
      this.show_dialog = val;
    },
    show_dialog(val) {
      this.$emit("update:show", val);
    },
  },
};
</script>